






























































import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import CustomersList from '@/components/business/customers/CustomersList.vue';
import {Getter} from 'vuex-class';
import SearchView from '@/components/SearchView.vue';
import PersonalContactListItem from '@/components/PersonalContactListItem.vue';
import ListGroupItem from '@/components/ListGroupItem.vue';
import {profileStore} from '@/store/modules/profile';

@Component({
  name: 'personal-contacts',
  components: {ListGroupItem, PersonalContactListItem, SearchView, CustomersList}
})
export default class PersonalContacts extends Vue {

  infoMessage: any = null;
  showInfo: boolean = false;
  errorMessage: any = null;
  showError: boolean = false;
  timeout: number = 3000;
  srcContacts?: any[] = [];
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''

  @Getter customers;

  get personalCustomers() {
    const id = profileStore.t2bUser!.id;
    return this.customers.filter((customer) => customer.personal && customer.personal.includes(id));
  }

  get groups() {
    const {srcContacts} = this;
    return [
      {
        title: 'Customer Contacts',
        items: srcContacts
      },
      {
        title: 'Associate Contacts',
        items: []
      }
    ];
  }

  get isGroups() {
    return this.groups.some((group) => group.items && group.items.length > 0)
  }

  @Watch('personalCustomers') onCustomersChanged(after, before) {
    if (!before) {
      return;
    }
    this.srcContacts = Object.assign([], this.personalCustomers);
  }

  search(term) {
    if (!term) {
      this.srcContacts = Object.assign([], this.personalCustomers);
      return;
    }
    const searchString = term.toLowerCase();
    const fFunc = (value) => value.fullName.toLowerCase().includes(searchString);
    this.srcContacts = this.personalCustomers!!.filter(fFunc);
  }

  onContactSelected(contact) {
    this.$router.push({name: 'personal-customer-profile', params: {customerId: contact.id, type: 'personal'}});
  }

  showToast(text) {
    this.infoMessage = text;
    this.showInfo = true;
  }

  showErrorToast(error) {
    this.errorMessage = error;
    this.showError = true;
  }

  onError(error) {
    this.showErrorToast(error);
  }

  onInfo(message) {
    this.showToast(message);
  }

  initEmpty() {
    const name = this.$route.name
    const subtype = this.$route.query.subtype;
    console.log('initEmpty', name)
    switch (name) {
      case 'personal':
        this.emptyIcon = require('../assets/_empty_states_contacts.svg')
        this.emptyTitle = 'No customer contacts yet'
        this.emptyDescription = 'New customers with whom<br>' +
            'you can initiate communication<br>' +
            'with will appear here'
        break
    }
  }

  mounted() {
    console.log('PersonalContacts :: mounted');
  }

  updated() {
    console.log('PersonalContacts :: updated');
    this.initEmpty()
  }

  created() {
    console.log('PersonalContacts :: created');
    this.srcContacts = Object.assign([], this.personalCustomers);
    this.initEmpty()
  };

  beforeDestroy() {
    console.log('PersonalContacts :: beforeDestroy');
  }
}
